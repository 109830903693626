import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import './HomeCards.css';

import {
  CardImg,
  CardBody,
  CardSubtitle,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container }
from 'reactstrap';

export default function HomeCardsQL() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: {
          fields: frontmatter___weekId,
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              visibility
              weekId
              title
              publishDate(formatString: "MMM DD, YYYY")
              weekImg {
                  publicURL
              }
              tags
            }
            parent {
              ... on File {
                modifiedTime(formatString: "MMM DD, YYYY")
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  const visibility =  false ? 'invisible' : 'visible';

  return (
    <div>
      <Container className='home-container my-5'>
        <Row>
          {data.allMarkdownRemark.edges.map((edge) => {
            return (
              <Col lg="3" md="6" xs="12" className={edge.node.frontmatter.visibility ? '' : 'd-none'} key={edge.node.frontmatter.weekId}>
                <Card className='my-2'>
                  <CardImg top width='80%' className='home-card my-0' src={edge.node.frontmatter.weekImg.publicURL} alt={edge.node.frontmatter.title} />
                  <CardBody className='cardbody-bg'>
                    <CardTitle className='h5 text-primary'>Week {edge.node.frontmatter.weekId}</CardTitle>
                    <CardSubtitle className='h5'>{edge.node.frontmatter.title}</CardSubtitle>
                    <CardText className='text-black-50'>{edge.node.frontmatter.tags}</CardText>
                    <CardText>
                      <small className="text-muted">Last updated on: {edge.node.parent.modifiedTime}</small>
                    </CardText>
                    <Button className='btn-warning' tag={Link} to={`/assignment/${edge.node.fields.slug}`}>More</Button>
                  </CardBody>
                </Card>
              </Col>
            );

            // console.log(data);
          })}

          </Row>
        </Container>
      </div>
  );
}
