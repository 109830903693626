import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout/Layout';
import HomeCards from '../components/HomeCards/HomeCards';
import HomeCardsQL from '../components/HomeCards/HomeCardsQL';

export default function Main({ location }) {
  return (
    <div>
      <SEO title="Home" />
      <Layout>
        {/* <h1>The location is {location.pathname}</h1> */}
        {/* <HomeCards /> */}
        <HomeCardsQL />
      </Layout>
    </div>
  );
}
